
.categories-lineup-section {
    background-color: #e8e8e8;
}


.category-box {
    height: 500px;
    border: 1px solid #e8e8e8;
    cursor: pointer;
}