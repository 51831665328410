
.hero-section-img-40 {
    width: 40px;
}

.hero-section-img-60 {
    width: 60px;
}

@media screen and (max-width: 900px) {
    .hero-section-box {
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
}