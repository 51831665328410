
.product-card {
    background-color: #fff;
    padding: 40px 40px 30px 45px;
}

.product-card-info-wrapper h3 {
    font-size: 1.625rem;
    font-weight: normal;
}

.product-card-info-wrapper span {
    font-weight: 700;
    color: #ad9567;
    font-size: 1.5rem;
}