
.cart-order-informations {
    background-color: #f1eeea;
}


.table td svg {
  cursor: pointer;
  font-size: 22px;
}

.table td svg:hover {
  color: #dc3642;
}

@media (max-width: 576px) {
    .table-responsive {
      display: block;
      max-width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-responsive table {
      width: 100%;
    }

    .table-responsive table thead {
      display: none;
    }

    .table-responsive table tbody {
      display: block;
    }

    .table-responsive table tr {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    .table-responsive table td {
      text-align: center;
      border-bottom: 0;
    }

    .table-responsive table tr th {
        border-bottom: 0;
    }

    .table-responsive table tr {
        border: 1px solid #80939c;
    }

    .cart-order-informations {
      margin-top: 40px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
    }
  }