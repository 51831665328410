

@media screen and (max-width: 900px) {
    .product-spotlight-section {
        text-align: center;
    }

    .product-spotlight-info-wrapper {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        margin-top: 20px;
    }
}
