
.button {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    border: 0;
    outline: 0;
    background-color: black;
    /* background-color: #ad9567; */
    color: white;
    border-radius: 6px;
}

.button:hover {
    background-color: #ad9567;
    transition: 0.5s;
}