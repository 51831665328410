
.home-top-section {
    background: url('../../Assets/Images/HomeTopSectionBackground.jpg');
    padding: 100px 150px;
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-home-top-section {
    display: none;
}

.title-home-top {
    font-size: 2.4rem;
}



@media screen and (max-width: 1026px) {
    .home-top-section {
        display: none;
    }

    .mobile-home-top-section {
        display: block;
    }

    .title-home-top {
        font-size: 1.5rem;
    }
}