@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Cormorant Garamond', serif;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

ul {
    margin-bottom: 0;
    padding-left: 0;
}

p {
    margin-bottom: 0;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.title-text {
    color: #000;
}

.grey-color {
    color: #80939c;
}

.dark-orange-color {
    color: #ad9567 !important;
}

.size-16 {
    font-size: 1rem;
}

.size-17 {
    font-size: 1.063rem;
}

.size-18 {
    font-size: 1.125rem;
}

.size-19 {
    font-size: 1.188rem;
}

.size-20 {
    font-size: 1.25rem;
}