
.testimonials-card {
    border: 1px solid #e7e7e7;;
    border-radius: 5px;
    box-shadow: 0px 5px 60px 0px #dde2ec;
    padding: 30px;
    gap: 3rem;
}

.testimonials-info-wrapper p {
    color: #80939c;
}

.testimonials-info-wrapper h6 {
    font-weight: 700;
    font-size: 1.125rem;
}

.testimonials-image-wrapper img {
    width: 70px;
    border-radius: 50%;
}


@media screen and (max-width: 900px) {
    .testimonials-card {
        flex-direction: column;
        text-align: center;
        gap: 20px;
    }

    .testimonials-info-wrapper {
        text-align: left;
    }
}