.single-category-section {
    background-color: #e8e8e8;
}


.single-category-title {
    font-size: 3rem;
}

.single_category_top_image {
    max-height: 400px;
    object-fit: contain;
}