
.footer-section {
    background-color: #f3dfbe;
}

.footer-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.footer-social-links li svg {
    font-size: 24px;
}

.footer-box p {
    font-size: 1.125rem;
}

@media screen and (max-width: 900px) {
    .footer-section .container .row{
        gap: 20px;
    }
}