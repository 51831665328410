
.navigation-section {
    padding: 20px;
    border-bottom: 2px solid #e8e8e8;
    background-color: black;
    color: #e8e8e8;
}

.navigation-icons-container li svg {
    font-size: 24px;
}

.navigation-container li {
    font-size: 20px;
}

.navigation-logo {
    width: 9.375rem;
}


@media screen and (max-width: 991px) {

    .navigation-middle-container {
        align-items: center;
        margin-top: 20px;
        gap: 26px;
    }

    .navigation-icons-container {
        justify-content: center;
        margin-top: 20px;
    }
}