.add-quantity-button {
    width: 52px;
    height: 52px;
    margin: 0 -1px 0 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    text-align: center;
    color: #000;
    background-color: transparent;
    border: 1px solid #d8d8d8;
    -webkit-user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media screen and (max-width: 991px) {
    .product-page-product-container button {
        margin-left: 0 !important;
    }

    .product-page-description {
        margin-top: 20px;
    }
}